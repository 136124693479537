import React from 'react'
import { Hidden, CssBaseline, Box } from '@mui/material'
import { css } from '@emotion/css'
import { SideNav } from 'components/SideNav'
import { rhythm } from 'utils/typography'

export interface LayoutProps {
  children: React.ReactNode
}

export function Layout(props: LayoutProps) {
  return (
    <Box style={{ display: 'flex' }}>
      <CssBaseline />
      <Hidden mdDown>
        <SideNav />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - 240px)` } }}
        >
          <div
            className={css`
              margin: ${rhythm(3)} ${rhythm(1)} 0px ${rhythm(1)};
            `}
          >
            {props?.children}
          </div>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <SideNav />
        <main
          className={css`
            margin: ${rhythm(3)} ${rhythm(1)} 0px ${rhythm(1)};
          `}
        >
          {props?.children}
        </main>
      </Hidden>
    </Box>
  )
}

export default Layout
