import React from 'react'
import { css } from '@emotion/css'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import { SnackbarAlert } from 'components/SnackbarAlert'
import {
  selectVehicleDetailErrorAlert,
  selectVehicleDetailInfoAlert,
  selectVehicleDetailSuccessAlert,
  closedAlert,
} from 'features/rental/vehicle-detail/vehicleDetailAlertSlice'

import { ButtonAddVehicleDetail } from 'features/rental/vehicle-detail/ButtonAddVehicleDetail'
import { TableVehicleDetail } from 'features/rental/vehicle-detail/TableVehicleDetail'

// todo dialog delete
// todo dialog edit
// todo upload media
export function VehicleDetail() {
  const errorAlert = useSelector(selectVehicleDetailErrorAlert)
  const infoAlert = useSelector(selectVehicleDetailInfoAlert)
  const successAlert = useSelector(selectVehicleDetailSuccessAlert)

  const dispatch = useAppDispatch()
  return (
    <>
      <SnackbarAlert
        action="error"
        open={errorAlert.isOpen}
        message={errorAlert.message}
        onClose={() => dispatch(closedAlert({ type: 'error' }))}
      />
      <SnackbarAlert
        action="info"
        open={infoAlert.isOpen}
        message={infoAlert.message}
        onClose={() => dispatch(closedAlert({ type: 'info' }))}
      />
      <SnackbarAlert
        action="success"
        open={successAlert.isOpen}
        message={successAlert.message}
        onClose={() => dispatch(closedAlert({ type: 'success' }))}
      />
      <h2>ข้อมูลรถ</h2>
      <div
        className={css`
          display: flex;
        `}
      >
        <ButtonAddVehicleDetail
          buttonProps={{
            className: css`
              margin-left: auto !important;
            `,
          }}
        />
      </div>

      <TableVehicleDetail />
    </>
  )
}

export default VehicleDetail
