import React from 'react'
import {
  TableHead as MuiTableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@mui/material'

export type Order = 'asc' | 'desc'

export interface HeadCell<Property> {
  disablePadding: boolean
  id: Property
  label: string
  numeric: boolean
}

export interface TableHeadProps<Property> {
  order: Order
  orderBy: Property
  headCells: HeadCell<Property>[]
  onRequestSort: (value: Property) => void
}

export function TableHead<P>(props: TableHeadProps<P>) {
  return (
    <MuiTableHead>
      <TableRow>
        <TableCell align="left">#</TableCell>
        {props?.headCells?.map((cell) => (
          <TableCell
            key={cell?.id + cell?.label}
            align={cell.numeric ? 'right' : 'left'}
            padding={cell.disablePadding ? 'none' : 'normal'}
            sortDirection={props?.orderBy === cell.id ? props?.order : false}
          >
            <TableSortLabel
              active={props?.orderBy === cell.id}
              direction={props?.orderBy === cell.id ? props?.order : 'asc'}
              onClick={(e) => props.onRequestSort(cell.id)}
            >
              {cell?.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  )
}

export default TableHead
