import React from 'react'
import { Dialog as MuiDialog, DialogTitle, DialogContent } from '@mui/material'

export interface DialogProps {
  isOpen: boolean
  onClose: () => void
  component: React.ReactNode
  headerText: string
}
export function Dialog(props: DialogProps) {
  return (
    <MuiDialog
      open={props?.isOpen}
      onClose={props?.onClose}
      aria-labelledby={`dialog-${props?.headerText}`}
    >
      <DialogContent>
        <DialogTitle>{props?.headerText}</DialogTitle>
        {props?.component}
      </DialogContent>
    </MuiDialog>
  )
}

export default Dialog
