import React from 'react'
import {
  createTheme,
  SimplePaletteColorOptions,
  ThemeProvider,
} from '@mui/material'
import { typography } from 'utils/typography'
import WrappedRoutes from './pages'

import './App.css'

typography.injectStyles()

const primary: SimplePaletteColorOptions = {
  light: '#398bef',
  main: '#1375ec',
  dark: '#126bd9',
  contrastText: '#ffffff',
}

const secondary: SimplePaletteColorOptions = {
  light: '#f6cf5a',
  main: '#dfaa0c',
  dark: '#b88d0a',
  contrastText: '#ffffff',
}

const error: SimplePaletteColorOptions = {
  light: '#f56668',
  main: '#f0191c',
  dark: '#c00c0f',
  contrastText: '#ffffff',
}

const theme = createTheme({
  palette: {
    primary,
    secondary,
    error,
  },
  typography: {
    fontFamily: ['Kanit'].join(''),
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <WrappedRoutes />
    </ThemeProvider>
  )
}

export default App
