import React, { useState } from 'react'
import { useAppDispatch } from 'store'
import { addedVehicleDetail } from 'features/rental/vehicle-detail/vehicleDetailSlice'
import { Button, ButtonProps } from 'components/Button'
import { Dialog } from 'components/Dialog'
import { FormVehicleDetail } from '../FormVehicleDetail'

export interface ButtonAddVehicleDetailProps {
  buttonProps?: ButtonProps
}

export function ButtonAddVehicleDetail(props: ButtonAddVehicleDetailProps) {
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false)
  const headerText = `เพิ่มข้อมูลรถ`

  const dispatch = useAppDispatch()

  function handleCloseClick() {
    setIsOpenDialog(false)
  }

  function handleSubmit(payload: any) {
    dispatch(addedVehicleDetail(payload))
    handleCloseClick()
  }

  return (
    <>
      <Button {...props?.buttonProps} onClick={() => setIsOpenDialog(true)}>
        {headerText}
      </Button>
      <Dialog
        onClose={handleCloseClick}
        isOpen={isOpenDialog}
        headerText={headerText}
        component={
          <FormVehicleDetail
            onClose={handleCloseClick}
            onSubmit={handleSubmit}
          />
        }
      />
    </>
  )
}

export default ButtonAddVehicleDetail
