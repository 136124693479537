import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import type { Stock, StockRowData, StockSchemaForm } from '../types'
import { completed } from './selectedStockSlice'
import { showAlert } from './stockAlertSlice'
import { formatISO, parseISO } from 'date-fns'
import { ConvertDateToTH } from 'utils/date-th'

interface StockState {
  entities: { [id: number]: Stock }
}

const initialState: StockState = {
  entities: [],
}

export const loadedStocks = createAsyncThunk('stocks/loadedStocks', () => {
  return [
    {
      id: '01',
      last_updated_at: formatISO(new Date()),
      license_number: 'กก 1111 กระบี่',
      status: 'working',
      vehicle: {
        id: 'vehicle-01',
        name: 'โตโยต้ายาริส',
      },
    },
    {
      id: '02',
      last_updated_at: formatISO(new Date()),
      license_number: 'กก 2222 กระบี่',
      status: 'out of service',
      vehicle: {
        id: 'vehicle-01',
        name: 'โตโยต้ายาริส',
      },
    },
  ]
})

export const removedStockById = createAsyncThunk(
  'stocks/removedStockById',
  (id: any, { dispatch }) => {
    dispatch(completed())
    dispatch(showAlert({ type: 'success', message: 'ลบสำเร็จ' }))
    return id
  }
)

export const updatedStockById = createAsyncThunk(
  'stocks/updatedStockById',
  (payload: StockSchemaForm & { id: string }, { dispatch }) => {
    dispatch(completed())
    dispatch(showAlert({ type: 'success', message: 'อัปเดทสำเร็จ' }))

    return {
      vehicle: {
        id: 'vehicle-01',
        name: 'โตโยต้ายาริส',
      },
      last_updated_at: formatISO(new Date()),
      license_number: payload.license_number,
      status: payload.status,
      id: payload.id,
    } as Stock
  }
)

export const addedStock = createAsyncThunk(
  'stocks/addedStock',
  (payload: StockSchemaForm, { dispatch }) => {
    dispatch(showAlert({ type: 'success', message: 'เพิ่มสำเร็จ' }))

    return {
      vehicle: {
        id: 'vehicle-01',
        name: 'โตโยต้ายาริส',
      },
      last_updated_at: formatISO(new Date()),
      license_number: payload.license_number,
      status: payload.status,
    } as Omit<Stock, 'id'>
  }
)

export const stockSlice = createSlice({
  name: 'stocks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        removedStockById.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { [action.payload]: removed, ...entities } = state.entities
          state.entities = entities
        }
      )
      .addCase(
        updatedStockById.fulfilled,
        (state, action: PayloadAction<any>) => {
          const payload = action.payload
          state.entities = { ...state.entities, [payload.id]: payload }
        }
      )
      .addCase(addedStock.fulfilled, (state, action: PayloadAction<any>) => {
        const id = Object.keys(state.entities).length + 100 + `${Math.random()}`
        const payload = action.payload
        state.entities = {
          ...state.entities,
          [id]: {
            id,
            ...payload,
          },
        }
      })
      .addCase(loadedStocks.fulfilled, (state, action: PayloadAction<any>) => {
        state.entities = action.payload.reduce(
          (entities: { [id: number]: Stock }, stock: Stock) => ({
            ...entities,
            [stock.id]: stock,
          }),
          {}
        )
      })
  },
})

export default stockSlice.reducer

const selectStocks = createSelector(
  (state: RootState) => state.rental,
  (state) => state.stocks
)

export const selectStockEntities = createSelector(
  selectStocks,
  (state) => state.entities
)

export const selectAllStocks = createSelector(selectStockEntities, (entities) =>
  Object.keys(entities).map((id: any) => entities[id])
)

export const selectStockById = createSelector(
  [selectStockEntities, (state, id) => id],
  (entities, id) => entities[id]
)

export const selectAllStockRows = createSelector(selectAllStocks, (stocks) =>
  stocks.map(
    (stock) =>
      ({
        id: stock.id,
        last_updated_at: ConvertDateToTH(parseISO(stock.last_updated_at)),
        license_number: stock.license_number,
        status: stock.status,
        vehicle_title: stock.vehicle.name,
      } as StockRowData)
  )
)
