import React, { useState } from 'react'
import { css } from '@emotion/css'
import {
  TableContainer,
  Table,
  TablePagination,
  TableBody,
} from '@mui/material'
import { TableHead, HeadCell, Order } from 'components/TableHead'
import { getComparator, stableSort } from 'utils/table-stable-sort'
import { ConvertDateToTH } from 'utils/date-th'
import { Price } from 'utils/price'
import { Data, Rows } from './Rows'
import { addDays } from 'date-fns'

type Property = keyof Data

const mocks: Data[] = [
  {
    id: '1',
    full_name: 'ยามาดะ ทาโร่',
    booking_id: 'aaxsds',
    booking_status: 'confirmed',
    payment_total_paid_amount: Price(1000),
    payment_status: 'partially paid',
    payment_total: Price(2000),
    license_number: 'กก 1111 กระบี่',
    pick_up_date_time: ConvertDateToTH(addDays(new Date(), 1)),
    pick_up_location: 'สนามบิน',
    return_date_time: ConvertDateToTH(addDays(new Date(), 3)),
    return_location: 'สนามบิน',
    vehicle_title: 'Toyota Yaris',
    last_updated_at: ConvertDateToTH(new Date()),
  },
  {
    id: '2',
    full_name: 'ยามาดะ ทาโร่',
    booking_id: 'bbasas',
    booking_status: 'confirmed',
    payment_total_paid_amount: Price(1000),
    payment_status: 'partially paid',
    payment_total: Price(2000),
    license_number: 'กก 2222 กระบี่',
    pick_up_date_time: ConvertDateToTH(addDays(new Date(), 2)),
    pick_up_location: 'สนามบิน',
    return_date_time: ConvertDateToTH(addDays(new Date(), 3)),
    return_location: 'สนามบิน',
    vehicle_title: 'Toyota Yaris',
    last_updated_at: ConvertDateToTH(new Date()),
  },
]

const headCells: HeadCell<Property>[] = [
  {
    id: 'booking_id',
    label: 'หมายเลขการจอง',
    disablePadding: false,
    numeric: false,
  },
  {
    id: 'full_name',
    label: 'ชื่อผู้จอง',
    disablePadding: false,
    numeric: false,
  },
  {
    id: 'booking_status',
    label: 'สถานะการจอง',
    disablePadding: false,
    numeric: false,
  },
  {
    id: 'payment_status',
    label: 'สถานะการชำระเงิน',
    disablePadding: false,
    numeric: false,
  },
  {
    id: 'pick_up_date_time',
    label: 'ระยะเวลา',
    disablePadding: false,
    numeric: false,
  },
  {
    id: 'pick_up_location',
    label: 'สถานที่',
    disablePadding: false,
    numeric: false,
  },
  {
    id: 'license_number',
    label: 'ข้อมูลรถ',
    disablePadding: false,
    numeric: false,
  },
  {
    id: 'last_updated_at',
    label: 'เปลี่ยนแปลงข้อมูลล่าสุด',
    disablePadding: false,
    numeric: false,
  },
]

export function TableReservation() {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<Property>('pick_up_date_time')
  const [rows] = useState<Data[]>(mocks)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  // const emptyRows = React.useMemo(
  //   () => (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0),
  //   [page, rowsPerPage, rows]
  // )

  function hanldeRequestSort(property: Property) {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  function handlePageChange(event: unknown, newPage: number) {
    setPage(newPage)
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  return (
    <div
      className={css`
        overflow-x: auto;
        width: 100%;
      `}
    >
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="table-stock">
          <TableHead<Property>
            headCells={headCells}
            onRequestSort={hanldeRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            <Rows
              headCells={headCells}
              data={stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
              onDelete={console.log}
              onEdit={console.log}
              onInfo={console.log}
            />
          </TableBody>
        </Table>
      </TableContainer>
      {!rows?.length && (
        <p
          className={css`
            text-align: center;
            text-decoration: underline;
          `}
        >
          ไม่มีข้อมูล
        </p>
      )}
      <TablePagination
        labelRowsPerPage="แถวต่อหน้า"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={handleRowsPerPageChange}
        onPageChange={handlePageChange}
      />
    </div>
  )
}

export default TableReservation
