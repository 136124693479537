import { createSlice, createSelector } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import type { AlertType } from '../types'

type VehicleDetailAlertState = Record<
  'error' | 'info' | 'success',
  { isOpen: boolean; message: string }
>

const initialState: VehicleDetailAlertState = {
  error: { isOpen: false, message: '' },
  info: { isOpen: false, message: '' },
  success: { isOpen: false, message: '' },
}

export const vehicleDetailAlertSlice = createSlice({
  name: 'vehicleDetailAlert',
  initialState,
  reducers: {
    showAlert: (
      state,
      action: PayloadAction<{ type: AlertType; message: string }>
    ) => {
      state[action.payload.type] = {
        isOpen: true,
        message: action.payload.message,
      }
    },
    closedAlert: (state, action: PayloadAction<{ type: AlertType }>) => {
      state[action.payload.type] = {
        isOpen: false,
        message: '',
      }
    },
  },
})

export default vehicleDetailAlertSlice.reducer

export const { closedAlert, showAlert } = vehicleDetailAlertSlice.actions

export const selectVehicleDetailAlert = createSelector(
  (state: RootState) => state.rental,
  (state) => state.vehicleDetailAlert
)

export const selectVehicleDetailInfoAlert = createSelector(
  selectVehicleDetailAlert,
  (state) => state.info
)

export const selectVehicleDetailSuccessAlert = createSelector(
  selectVehicleDetailAlert,
  (state) => state.success
)

export const selectVehicleDetailErrorAlert = createSelector(
  selectVehicleDetailAlert,
  (state) => state.error
)
