import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import type { VehicleDetail } from '../types'
import { ConvertVehicleType } from 'utils/convert-vehicle-type'
import { completed } from './selectedVehicleDetailSlice'
import { showAlert } from './vehicleDetailAlertSlice'

const mocks: VehicleDetail[] = [
  {
    id: '01',
    en_title: 'Toyota Yaris01',
    th_title: 'โตโยต้ายาริส',
    vehicle_type: 'sedan',
  },
  {
    id: '02',
    en_title: 'Toyota Yaris02',
    th_title: 'โตโยต้ายาริส',
    vehicle_type: 'sedan',
  },
  {
    id: '03',
    en_title: 'Toyota Yaris03',
    th_title: 'โตโยต้ายาริส',
    vehicle_type: 'sedan',
  },
  {
    id: '04',
    en_title: 'Toyota Yaris04',
    th_title: 'โตโยต้ายาริส',
    vehicle_type: 'sedan',
  },
  {
    id: '05',
    en_title: 'Toyota Yaris05',
    th_title: 'โตโยต้ายาริส',
    vehicle_type: 'sedan',
  },
  {
    id: '06',
    en_title: 'Toyota Yaris06',
    th_title: 'โตโยต้ายาริส',
    vehicle_type: 'sedan',
  },
]

interface VehicleDetailState {
  entities: { [id: number]: VehicleDetail }
}

const initialState: VehicleDetailState = {
  entities: mocks.reduce(
    (
      entities: { [id: number]: VehicleDetail },
      vehicleDetail: VehicleDetail
    ) => ({
      ...entities,
      [vehicleDetail.id]: vehicleDetail,
    }),
    {}
  ),
}

export const removedVehicleDetailById = createAsyncThunk(
  'vehicleDetails/removedVehicleDetailById',
  (id: any, { dispatch }) => {
    dispatch(completed())
    dispatch(showAlert({ type: 'success', message: 'ลบสำเร็จ' }))
    return id
  }
)

export const updatedVehicleDetailById = createAsyncThunk(
  'vehicleDetails/updatedVehicleDetailById',
  (payload: any, { dispatch }) => {
    dispatch(completed())
    dispatch(showAlert({ type: 'success', message: 'อัปเดทสำเร็จ' }))
    return payload
  }
)

export const addedVehicleDetail = createAsyncThunk(
  'vehicleDetails/addedVehicleDetail',
  (payload: any, { dispatch }) => {
    dispatch(showAlert({ type: 'success', message: 'เพิ่มสำเร็จ' }))
    return payload
  }
)

export const vehicleDetailSlice = createSlice({
  name: 'vehicleDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        removedVehicleDetailById.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { [action.payload]: removed, ...entities } = state.entities
          state.entities = entities
        }
      )
      .addCase(
        updatedVehicleDetailById.fulfilled,
        (state, action: PayloadAction<any>) => {
          const payload = action.payload
          state.entities = { ...state.entities, [payload.id]: payload }
        }
      )
      .addCase(
        addedVehicleDetail.fulfilled,
        (state, action: PayloadAction<any>) => {
          const id = Object.keys(state.entities).length + 100
          state.entities = {
            ...state.entities,
            [id]: { ...action.payload, id },
          }
        }
      )
  },
})

export default vehicleDetailSlice.reducer

const selectVehicleDetails = createSelector(
  (state: RootState) => state.rental,
  (state) => state.vehicleDetails
)

export const selectVehicleDetailEntities = createSelector(
  selectVehicleDetails,
  (state) => state.entities
)

export const selectAllVehicleDetails = createSelector(
  selectVehicleDetailEntities,
  (entities) => Object.keys(entities).map((id: any) => entities[id])
)

export const selectVehicleDetailById = createSelector(
  [selectVehicleDetailEntities, (state, id) => id],
  (entities, id) => entities[id]
)

export const selectAllVehicleDetailRows = createSelector(
  selectAllVehicleDetails,
  (vehicleDetails) =>
    vehicleDetails.map((vehicleDetail) => ({
      ...vehicleDetail,
      vehicle_type: ConvertVehicleType(vehicleDetail.vehicle_type as any),
    }))
)
