export function Price(
  price: any,
  langKey: string = 'TH',
  currency: string = 'THB'
) {
  let value = +price
  if (!price) {
    value = 0
  }
  return new Intl.NumberFormat(langKey, { style: 'currency', currency }).format(
    value
  )
}

export default Price
