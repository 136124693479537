import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  TextField,
  DialogActions,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  MenuItem,
  Stack,
  Checkbox,
} from '@mui/material'
import { Button as MuiButton } from 'components/Button'
import * as yup from 'yup'
import { rhythm } from 'utils/typography'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

const ErrorMessage = styled.span`
  color: var(--color-error-main);
`

const Button = styled(MuiButton)`
  margin-left: ${rhythm(0.5)};
`

const schema = yup.object({
  planProvider: yup.object({
    counter: yup.string().max(128, 'กรุณากรอกน้อยกว่า 128 ตัวอักษร'),
    online: yup.string().max(128, 'กรุณากรอกน้อยกว่า 128 ตัวอักษร'),
  }),
  planStatus: yup.string().required('กรุณากรอกข้อมูล'),
  deposit: yup
    .number()
    .min(0, 'กรุณากรอกจำนวนเต็ม')
    .required('กรุณากรอกข้อมูล'),
  discount: yup
    .number()
    .min(0, 'กรุณากรอกจำนวนเต็ม')
    .required('กรุณากรอกข้อมูล'),
  price: yup.number().min(0, 'กรุณากรอกจำนวนเต็ม').required('กรุณากรอกข้อมูล'),
  planDate: yup.date().required('กรุณากรอกข้อมูล'),
  licensePlate: yup.string().required('กรุณากรอกข้อมูล'),
})

export interface PlanProvider {
  counter: boolean
  online: boolean
}

export type PlanStatus = 'lock' | 'return' | 'vacant' | 'occupied'
export type PlanStatusTH = 'ล็อก' | 'คืนรถ' | 'ว่าง' | 'ไม่ว่าง'

export interface Schema {
  planProvider?: PlanProvider
  planStatus?: PlanStatus
  deposit?: number
  discount?: number
  price?: number
  planDate?: Date
  licensePlate?: string
}

export interface FormPlanProps {
  licensePlates: { id: string; name: string }[]
  defaultValues?: Schema
  onSubmit: (value: any) => void
  onClose: () => void
}

export function FormPlan(props: FormPlanProps) {
  const {
    formState: { isValid, errors },
    register,
    handleSubmit,
    control,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: props?.defaultValues,
  })

  const formText = {
    planProvider: {
      label: 'ช่องทางการจอง',
      placeholder: '',
    },
    planStatus: {
      label: 'สถานะแผนกำหนดการ',
      placeholder: 'เลือกสถานะแผนกำหนดการ',
    },
    deposit: {
      label: 'เงินมัดจำ',
      placeholder: 'พิมพ์จำนวนเงินมัดจำ',
    },
    discount: {
      label: 'ส่วนลด',
      placeholder: 'พิมพ์จำนวนส่วนลด',
    },
    price: {
      label: 'ราคา',
      placeholder: 'พิมพ์จำนวนราคาเช่า',
    },
    planDate: {
      label: 'วันที่แผนการจอง',
      placeholder: 'พิมพ์วันที่แผนการจอง',
    },
    licensePlate: {
      label: 'รุ่นรถ',
      placeholder: 'เลือกรุ่นรถ',
    },
    btn: {
      success: 'บันทึก',
      discard: 'ยกเลิก',
    },
  }

  return (
    <form onSubmit={handleSubmit(props?.onSubmit)} autoComplete="off">
      <Stack
        spacing={4}
        className={css`
          margin-top: ${rhythm(1)};
        `}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            control={control}
            name="planDate"
            render={({ field }) => (
              <DesktopDatePicker
                {...field}
                label="Date desktop"
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => <TextField {...params} />}
              />
            )}
          />
        </LocalizationProvider>
        <Controller
          control={control}
          name="licensePlate"
          render={({ field, formState: { errors } }) => (
            <TextField
              select
              fullWidth
              {...field}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label={formText?.licensePlate?.label}
              placeholder={formText?.licensePlate?.placeholder}
              error={
                Boolean(errors?.licensePlate) &&
                Boolean(errors?.licensePlate?.message)
              }
              helperText={
                <ErrorMessage>
                  {errors?.licensePlate && errors?.licensePlate?.message}
                </ErrorMessage>
              }
            >
              {props?.licensePlates?.map((item) => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <TextField
          {...register('price')}
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          name="price"
          label={formText?.price?.label}
          placeholder={formText?.price?.placeholder}
          error={Boolean(errors?.price) && Boolean(errors?.price?.message)}
          helperText={
            <ErrorMessage>
              {errors?.price && errors?.price?.message}
            </ErrorMessage>
          }
        />
        <TextField
          {...register('deposit')}
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          name="deposit"
          label={formText?.deposit?.label}
          placeholder={formText?.deposit?.placeholder}
          error={Boolean(errors?.deposit) && Boolean(errors?.deposit?.message)}
          helperText={
            <ErrorMessage>
              {errors?.deposit && errors?.deposit?.message}
            </ErrorMessage>
          }
        />
        <TextField
          {...register('discount')}
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          name="discount"
          label={formText?.discount?.label}
          placeholder={formText?.discount?.placeholder}
          error={
            Boolean(errors?.discount) && Boolean(errors?.discount?.message)
          }
          helperText={
            <ErrorMessage>
              {errors?.discount && errors?.discount?.message}
            </ErrorMessage>
          }
        />
        <Controller
          name="planStatus"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>{formText?.planStatus?.label}</FormLabel>
              <RadioGroup {...field}>
                <FormControlLabel
                  value={'vacant' as PlanStatus}
                  control={<Radio />}
                  label={'ว่าง' as PlanStatusTH}
                />
                <FormControlLabel
                  value={'occupied' as PlanStatus}
                  control={<Radio />}
                  label={'ไม่ว่าง' as PlanStatusTH}
                />
                <FormControlLabel
                  value={'return' as PlanStatus}
                  control={<Radio />}
                  label={'คืนรถ' as PlanStatusTH}
                />
                <FormControlLabel
                  value={'lock' as PlanStatus}
                  control={<Radio />}
                  label={'ล็อก' as PlanStatusTH}
                />
              </RadioGroup>
            </FormControl>
          )}
        />
        <p
          className={css`
            text-decoration: underline;
          `}
        >
          ช่องทางการจอง
        </p>
        <Controller
          name="planProvider.online"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              {...field}
              label="เว็บออนไลน์"
              labelPlacement="end"
              control={<Checkbox />}
            />
          )}
        />
        <Controller
          name="planProvider.counter"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              {...field}
              label="เคาเตอร์"
              labelPlacement="end"
              control={<Checkbox />}
            />
          )}
        />
      </Stack>
      <br />
      <DialogActions>
        <Button
          type="button"
          color="inherit"
          variant="text"
          onClick={props?.onClose}
        >
          {formText?.btn?.discard}
        </Button>
        <Button type="submit" disabled={!isValid}>
          {formText?.btn?.success}
        </Button>
      </DialogActions>
    </form>
  )
}

export default FormPlan
