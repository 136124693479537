import React from 'react'

export function Dashboard() {
  return (
    <>
      <h2>ภาพรวม</h2>
      <p>การ์ดสรุปแต่ละวันได้แก่ รับรถ คืนรถ </p>
      <p>ตารางการจองวันนี้</p>
    </>
  )
}

export default Dashboard
