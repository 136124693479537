import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'

import { selectStockById } from './stockSlice'
import { Stock } from '../types'

interface SelectedStockState {
  action: 'update' | 'delete' | null
  entity: Stock | null
}

const initialState: SelectedStockState = {
  action: null,
  entity: null,
}

export const selectedUpdateById = createAsyncThunk(
  'stocks/selectedUpdateById',
  async (id: string, { getState }) => {
    return selectStockById(getState() as RootState, id)
  }
)

export const selectedDeleteById = createAsyncThunk(
  'stocks/selectedDeleteById',
  async (id: string, { getState }) => {
    return selectStockById(getState() as RootState, id)
  }
)

export const selectedStockSlice = createSlice({
  name: 'selectedStock',
  initialState,
  reducers: {
    completed: (state) => {
      state.action = null
      state.entity = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(selectedUpdateById.fulfilled, (state, action) => {
        state.action = 'update'
        state.entity = action.payload
      })
      .addCase(
        selectedDeleteById.fulfilled,
        (state, action: PayloadAction<Stock>) => {
          state.action = 'delete'
          state.entity = action.payload
        }
      )
  },
})

export default selectedStockSlice.reducer

export const { completed } = selectedStockSlice.actions

export const selectSelectedStock = createSelector(
  (state: RootState) => state.rental,
  (state) => state.selectedStock
)
