import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import NoMatch from './NoMatch'

import Dashboard from './Dashboard'
import InBoxes from './InBoxes'
import Rental from './Rental'
import Staffs from './Staffs'

export function WrappedRoutes() {
  return (
    <Router>
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/inboxes" element={<InBoxes />} />
        <Route path="/rental/*" element={<Rental />} />
        <Route path="/staffs" element={<Staffs />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Router>
  )
}

export default WrappedRoutes
