import React from 'react'
import { HeadCell } from 'components/TableHead'
import { css } from '@emotion/css'
import { TableRow, TableCell, Menu, MenuItem, IconButton } from '@mui/material'
import { MoreVert as MoreIcon } from '@mui/icons-material'

export interface Data {
  id: string
  th_title: string
  en_title: string
  vehicle_type: string
}

export interface RowsProps {
  headCells: HeadCell<keyof Data>[]
  data: Data[]
  onEdit: (value: Data) => void
  onDelete: (value: Data) => void
}

export function Rows(props: RowsProps) {
  const RenderCells = props?.data?.map((payload) => {
    return (
      <TableRow key={payload?.id} hover>
        <MoreCell
          onDelete={() => props?.onDelete(payload)}
          onEdit={() => props?.onEdit(payload)}
        />
        {props.headCells?.map((cell) => (
          <TableCell
            key={cell?.id + cell?.label}
            align={cell.numeric ? 'right' : 'left'}
            padding={cell.disablePadding ? 'none' : 'normal'}
          >
            {payload[cell?.id]}
          </TableCell>
        ))}
      </TableRow>
    )
  })
  return <>{RenderCells}</>
}

interface MoreCellProps {
  onEdit: () => void
  onDelete: () => void
}
function MoreCell(props: MoreCellProps) {
  const menuText = {
    edit: 'แก้ไขข้อมูล',
    delete: 'ลบข้อมูล',
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <TableCell padding="checkbox">
      <IconButton aria-label="more row" size="small" onClick={handleClick}>
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            props?.onEdit()
            handleClose()
          }}
        >
          {menuText?.edit}
        </MenuItem>
        <MenuItem
          className={css`
            color: red !important;
          `}
          onClick={() => {
            props?.onDelete()
            handleClose()
          }}
        >
          {menuText?.delete}
        </MenuItem>
      </Menu>
    </TableCell>
  )
}
