import { createSlice, createSelector } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import type { AlertType } from '../types'

type LocationAlertState = Record<
  'error' | 'info' | 'success',
  { isOpen: boolean; message: string }
>

const initialState: LocationAlertState = {
  error: { isOpen: false, message: '' },
  info: { isOpen: false, message: '' },
  success: { isOpen: false, message: '' },
}

export const locationAlertSlice = createSlice({
  name: 'locationAlert',
  initialState,
  reducers: {
    showAlert: (
      state,
      action: PayloadAction<{ type: AlertType; message: string }>
    ) => {
      state[action.payload.type] = {
        isOpen: true,
        message: action.payload.message,
      }
    },
    closedAlert: (state, action: PayloadAction<{ type: AlertType }>) => {
      state[action.payload.type] = {
        isOpen: false,
        message: '',
      }
    },
  },
})

export default locationAlertSlice.reducer

export const { closedAlert, showAlert } = locationAlertSlice.actions

export const selectLocationAlert = createSelector(
  (state: RootState) => state.rental,
  (state) => state.locationAlert
)

export const selectLocationInfoAlert = createSelector(
  selectLocationAlert,
  (state) => state.info
)

export const selectLocationSuccessAlert = createSelector(
  selectLocationAlert,
  (state) => state.success
)

export const selectLocationErrorAlert = createSelector(
  selectLocationAlert,
  (state) => state.error
)
