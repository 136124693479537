import React, { useState } from 'react'
import { HeadCell } from 'components/TableHead'
import { css } from '@emotion/css'
import { TableRow, TableCell, Menu, MenuItem, IconButton } from '@mui/material'
import { MoreVert as MoreIcon } from '@mui/icons-material'

export interface Data {
  id: string
  full_name: string
  booking_id: string
  booking_status: string
  payment_total_paid_amount: string
  payment_total: string
  payment_status: string
  pick_up_date_time: string
  return_date_time: string
  pick_up_location: string
  return_location: string
  license_number: string
  vehicle_title: string
  last_updated_at: string
}

export interface RowsProps {
  headCells: HeadCell<keyof Data>[]
  data: Data[]
  onEdit: (value: Data) => void
  onDelete: (value: Data) => void
  onInfo: (value: Data) => void
}

export function Rows(props: RowsProps) {
  const [selected, setSelected] = useState<string | undefined>(undefined)

  let headCells = new Map<keyof Data, HeadCell<keyof Data>>()
  props.headCells.forEach((cell) => headCells.set(cell.id, cell))

  const BookingIdCell = (payload: Data) => (
    <TableCell
      align={headCells.get('booking_id')?.numeric ? 'right' : 'left'}
      padding={headCells.get('booking_id')?.disablePadding ? 'none' : 'normal'}
    >
      {payload?.booking_id}
    </TableCell>
  )

  const FullNameCell = (payload: Data) => (
    <TableCell
      align={headCells.get('full_name')?.numeric ? 'right' : 'left'}
      padding={headCells.get('full_name')?.disablePadding ? 'none' : 'normal'}
    >
      {payload?.full_name}
    </TableCell>
  )

  const BookingStatusCell = (payload: Data) => (
    <TableCell
      align={headCells.get('booking_status')?.numeric ? 'right' : 'left'}
      padding={
        headCells.get('booking_status')?.disablePadding ? 'none' : 'normal'
      }
    >
      {payload?.booking_status}
    </TableCell>
  )

  const PaymentCell = (payload: Data) => (
    <TableCell
      align={headCells.get('payment_status')?.numeric ? 'right' : 'left'}
      padding={
        headCells.get('payment_status')?.disablePadding ? 'none' : 'normal'
      }
    >
      <div>{payload?.payment_status}</div>
      <div>
        {payload?.payment_total_paid_amount}/{payload?.payment_total}
      </div>
    </TableCell>
  )

  const DurationCell = (payload: Data) => (
    <TableCell
      align={headCells.get('pick_up_date_time')?.numeric ? 'right' : 'left'}
      padding={
        headCells.get('pick_up_date_time')?.disablePadding ? 'none' : 'normal'
      }
    >
      <div>{payload?.pick_up_date_time}</div>
      <div>{payload?.return_date_time}</div>
    </TableCell>
  )

  const LocationCell = (payload: Data) => (
    <TableCell
      align={headCells.get('pick_up_location')?.numeric ? 'right' : 'left'}
      padding={
        headCells.get('pick_up_location')?.disablePadding ? 'none' : 'normal'
      }
    >
      <div>{payload?.pick_up_location}</div>
      <div>{payload?.return_location}</div>
    </TableCell>
  )
  const VehicleDetailCell = (payload: Data) => (
    <TableCell
      align={headCells.get('license_number')?.numeric ? 'right' : 'left'}
      padding={
        headCells.get('license_number')?.disablePadding ? 'none' : 'normal'
      }
    >
      <div>{payload?.license_number}</div>
      <div>{payload?.vehicle_title}</div>
    </TableCell>
  )

  const LastUpdatedAtCell = (payload: Data) => (
    <TableCell
      align={headCells.get('last_updated_at')?.numeric ? 'right' : 'left'}
      padding={
        headCells.get('last_updated_at')?.disablePadding ? 'none' : 'normal'
      }
    >
      {payload?.last_updated_at}
    </TableCell>
  )

  const RenderCells = props?.data?.map((payload) => {
    return (
      <TableRow
        key={payload?.id}
        hover
        selected={selected === payload?.id}
        onClick={() => {
          setSelected((prev) =>
            prev !== payload?.id ? payload?.id : undefined
          )
        }}
      >
        <MoreCell
          onDelete={() => props?.onDelete(payload)}
          onEdit={() => props?.onEdit(payload)}
          onInfo={() => props?.onInfo(payload)}
        />
        {BookingIdCell(payload)}
        {FullNameCell(payload)}
        {BookingStatusCell(payload)}
        {PaymentCell(payload)}
        {DurationCell(payload)}
        {LocationCell(payload)}
        {VehicleDetailCell(payload)}
        {LastUpdatedAtCell(payload)}
      </TableRow>
    )
  })
  return <>{RenderCells}</>
}

export default Rows

interface MoreCellProps {
  onEdit: () => void
  onDelete: () => void
  onInfo: () => void
}
function MoreCell(props: MoreCellProps) {
  const menuText = {
    edit: 'แก้ไขข้อมูล',
    delete: 'ลบข้อมูล',
    info: 'รายละเอียดข้อมูล',
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <TableCell padding="checkbox">
      <IconButton aria-label="more row" size="small" onClick={handleClick}>
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            props?.onInfo()
            handleClose()
          }}
        >
          {menuText?.info}
        </MenuItem>
        <MenuItem
          onClick={() => {
            props?.onEdit()
            handleClose()
          }}
        >
          {menuText?.edit}
        </MenuItem>
        <MenuItem
          className={css`
            color: red !important;
          `}
          onClick={() => {
            props?.onDelete()
            handleClose()
          }}
        >
          {menuText?.delete}
        </MenuItem>
      </Menu>
    </TableCell>
  )
}
