import React, { useState } from 'react'
import { css } from '@emotion/css'
import {
  TableContainer,
  Table,
  TablePagination,
  TableBody,
} from '@mui/material'
import { TableHead, HeadCell, Order } from 'components/TableHead'
import { Rows, Data } from './Rows'
import { getComparator, stableSort } from 'utils/table-stable-sort'
import { Dialog, DialogContentDelete } from 'components/Dialog'

import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
  completed,
  selectedDeleteById,
  selectSelectedStock,
  selectedUpdateById,
} from 'features/rental/stock/selectedStockSlice'
import {
  selectAllStockRows,
  removedStockById,
  updatedStockById,
} from 'features/rental/stock/stockSlice'
import { FormStock } from 'features/rental/stock/FormStock'

type Property = keyof Data

const headCells: HeadCell<Property>[] = [
  {
    id: 'license_number',
    label: 'ป้ายทะเบียนรถ',
    disablePadding: true,
    numeric: false,
  },
  {
    id: 'vehicle_title',
    label: 'รุ่นรถ',
    disablePadding: true,
    numeric: false,
  },
  {
    id: 'status',
    label: 'สถานะรถ',
    disablePadding: true,
    numeric: false,
  },
  {
    id: 'last_updated_at',
    label: 'เวลาแก้ไขล่าสุด',
    disablePadding: false,
    numeric: false,
  },
]

export function TableStock() {
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<Property>('license_number')

  const rows = useSelector(selectAllStockRows)

  const selectedStock = useSelector(selectSelectedStock)
  const dispatch = useAppDispatch()

  function hanleEditClick(payload: any) {
    dispatch(selectedUpdateById(payload.id))
  }
  function handleDialogEditClose() {
    dispatch(completed())
  }
  function handleDeleteClick(payload: any) {
    dispatch(selectedDeleteById(payload.id))
  }

  function handleDialogDeleteClose() {
    dispatch(completed())
  }

  function handleSubmit(payload: any) {
    dispatch(updatedStockById(payload))
  }

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  // const emptyRows = React.useMemo(
  //   () => (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0),
  //   [page, rowsPerPage, rows]
  // )

  function hanldeRequestSort(property: Property) {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  function handlePageChange(event: unknown, newPage: number) {
    setPage(newPage)
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  return (
    <>
      <Dialog
        headerText="แก้ไข"
        component={
          <FormStock
            onClose={handleDialogEditClose}
            onSubmit={(payload) =>
              handleSubmit({ ...payload, id: selectedStock.entity?.id })
            }
            defaultValues={{
              license_number: selectedStock.entity?.license_number,
              status: selectedStock.entity?.status,
              vehicle: selectedStock.entity?.vehicle.id,
            }}
            vehicles={[
              { id: 'vehicle-01', name: 'toyota' },
              { id: 'vehicle-02', name: 'mazda' },
            ]}
          />
        }
        isOpen={selectedStock.action === 'update'}
        onClose={handleDialogEditClose}
      />
      <Dialog
        headerText={`ต้องการลบข้อมูล ${selectedStock.entity?.license_number}`}
        component={
          <DialogContentDelete
            content={null}
            onDiscard={handleDialogDeleteClose}
            onSuccess={() =>
              dispatch(removedStockById(selectedStock.entity?.id))
            }
          />
        }
        isOpen={selectedStock.action === 'delete'}
        onClose={handleDialogDeleteClose}
      />
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="table-stock">
          <TableHead<Property>
            headCells={headCells}
            onRequestSort={hanldeRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            <Rows
              data={stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
              headCells={headCells}
              onDelete={handleDeleteClick}
              onEdit={hanleEditClick}
            />
          </TableBody>
        </Table>
      </TableContainer>
      {!rows?.length && (
        <p
          className={css`
            text-align: center;
            text-decoration: underline;
          `}
        >
          ไม่มีข้อมูล
        </p>
      )}
      <TablePagination
        labelRowsPerPage="แถวต่อหน้า"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={handleRowsPerPageChange}
        onPageChange={handlePageChange}
      />
    </>
  )
}

export default TableStock
