import React, { useState } from 'react'
import { css } from '@emotion/css'
import {
  TableContainer,
  Table,
  TablePagination,
  TableBody,
} from '@mui/material'
import { TableHead, HeadCell, Order } from 'components/TableHead'
import { Rows, LocationData } from './Rows'
import { getComparator, stableSort } from 'utils/table-stable-sort'
import { Dialog, DialogContentDelete } from 'components/Dialog'

import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
  completed,
  selectedDeleteById,
  selectSelectedLocation,
  selectedUpdateById,
} from 'features/rental/location/selectedLocationSlice'
import {
  selectAllLocationRows,
  removedLocationById,
  updatedLocationById,
} from 'features/rental/location/locationSlice'
import { FormLocation } from 'features/rental/location/FormLocation'

type Property = 'th_title' | 'en_title' | 'rate'

const headCells: HeadCell<Property>[] = [
  {
    id: 'th_title',
    label: 'ชื่อภาษาไทย',
    disablePadding: true,
    numeric: false,
  },
  {
    id: 'en_title',
    label: 'ชื่อภาษาอังกฤษ',
    disablePadding: true,
    numeric: false,
  },
  {
    id: 'rate',
    label: 'เรทราคา',
    disablePadding: false,
    numeric: true,
  },
]
// ? container
export function TableLocation() {
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<Property>('en_title')

  const rows = useSelector(selectAllLocationRows)

  const selectedLocation = useSelector(selectSelectedLocation)
  const dispatch = useAppDispatch()

  function hanleEditClick(payload: LocationData) {
    dispatch(selectedUpdateById(payload.id))
  }
  function handleDialogEditClose() {
    dispatch(completed())
  }
  function handleDeleteClick(payload: LocationData) {
    dispatch(selectedDeleteById(payload.id))
  }

  function handleDialogDeleteClose() {
    dispatch(completed())
  }

  function handleSubmit(payload: any) {
    dispatch(updatedLocationById(payload))
  }

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  // const emptyRows = React.useMemo(
  //   () => (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0),
  //   [page, rowsPerPage, rows]
  // )

  function hanldeRequestSort(property: Property) {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  function handlePageChange(event: unknown, newPage: number) {
    setPage(newPage)
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  return (
    <>
      <Dialog
        headerText="แก้ไข"
        component={
          <FormLocation
            onClose={handleDialogEditClose}
            onSubmit={(payload) =>
              handleSubmit({ ...payload, id: selectedLocation.entity?.id })
            }
            defaultValues={{
              en_title: selectedLocation.entity?.en_title,
              rate: selectedLocation.entity?.rate,
              th_title: selectedLocation.entity?.th_title,
            }}
          />
        }
        isOpen={selectedLocation.action === 'update'}
        onClose={handleDialogEditClose}
      />
      <Dialog
        headerText={`ต้องการลบข้อมูล ${selectedLocation.entity?.th_title}`}
        component={
          <DialogContentDelete
            content={null}
            onDiscard={handleDialogDeleteClose}
            onSuccess={() =>
              dispatch(removedLocationById(selectedLocation.entity?.id))
            }
          />
        }
        isOpen={selectedLocation.action === 'delete'}
        onClose={handleDialogDeleteClose}
      />
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="table-location">
          <TableHead<Property>
            headCells={headCells}
            onRequestSort={hanldeRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            <Rows
              data={
                stableSort(rows, getComparator(order, orderBy)).slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ) || []
              }
              headCells={headCells}
              onDelete={handleDeleteClick}
              onEdit={hanleEditClick}
            />
          </TableBody>
        </Table>
      </TableContainer>
      {!rows?.length && (
        <p
          className={css`
            text-align: center;
            text-decoration: underline;
          `}
        >
          ไม่มีข้อมูล
        </p>
      )}
      <TablePagination
        labelRowsPerPage="แถวต่อหน้า"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={handleRowsPerPageChange}
        onPageChange={handlePageChange}
      />
    </>
  )
}

export default TableLocation
