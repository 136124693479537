import React from 'react'
import styled from '@emotion/styled'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  TextField as MuiTextField,
  DialogActions,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  MenuItem,
} from '@mui/material'
import { Button as MuiButton } from 'components/Button'
import * as yup from 'yup'
import { rhythm } from 'utils/typography'

export type Status = 'working' | 'out of service'

const ErrorMessage = styled.span`
  color: var(--color-error-main);
`

const TextField = styled(MuiTextField)`
  margin-top: ${rhythm(0.7)};
  margin-bottom: ${rhythm(0.7)};
`

const Button = styled(MuiButton)`
  margin-left: ${rhythm(0.5)};
`

const schema = yup.object({
  license_number: yup
    .string()
    .max(128, 'กรุณากรอกน้อยกว่า 128 ตัวอักษร')
    .required('กรุณากรอกข้อมูล'),
  status: yup.string().required('กรุณากรอกข้อมูล'),
  vehicle: yup.string().required('กรุณากรอกข้อมูล'),
})

export interface Schema {
  license_number?: string
  status?: Status
  vehicle?: string
}

export interface FormStockProps {
  vehicles: { id: string; name: string }[]
  defaultValues?: Schema
  onSubmit: (value: any) => void
  onClose: () => void
}

export function FormStock(props: FormStockProps) {
  const {
    formState: { isValid, errors },
    register,
    handleSubmit,
    control,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: props?.defaultValues,
  })

  const formText = {
    license_number: {
      label: 'หมายเลขทะเบียนรถ',
      placeholder: 'พิมพ์หมายเลขทะเบียนรถ',
    },
    status: {
      label: 'สถานะ',
      placeholder: '',
    },
    vehicle: {
      label: 'รุ่นรถ',
      placeholder: 'เลือกรุ่นรถ',
    },
    btn: {
      success: 'บันทึก',
      discard: 'ยกเลิก',
    },
  }

  return (
    <form onSubmit={handleSubmit(props?.onSubmit)} autoComplete="off">
      <TextField
        {...register('license_number')}
        fullWidth
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        name="license_number"
        label={formText?.license_number?.label}
        placeholder={formText?.license_number?.placeholder}
        error={
          Boolean(errors?.license_number) &&
          Boolean(errors?.license_number?.message)
        }
        helperText={
          <ErrorMessage>
            {errors?.license_number && errors?.license_number?.message}
          </ErrorMessage>
        }
      />
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <FormControl>
            <FormLabel>{formText?.status?.label}</FormLabel>
            <RadioGroup {...field}>
              <FormControlLabel
                value={'working' as Status}
                control={<Radio />}
                label={'working' as Status}
              />
              <FormControlLabel
                value={'out of service' as Status}
                control={<Radio />}
                label={'out of service' as Status}
              />
            </RadioGroup>
          </FormControl>
        )}
      />
      <Controller
        name="vehicle"
        control={control}
        render={({ field, formState: { errors } }) => (
          <TextField
            select
            fullWidth
            {...field}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            label={formText?.vehicle?.label}
            placeholder={formText?.vehicle?.placeholder}
            error={
              Boolean(errors?.vehicle) && Boolean(errors?.vehicle?.message)
            }
            helperText={
              <ErrorMessage>
                {errors?.vehicle && errors?.vehicle?.message}
              </ErrorMessage>
            }
          >
            {props?.vehicles?.map((item) => (
              <MenuItem key={item?.id} value={item?.id}>
                {item?.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <br />
      <DialogActions>
        <Button
          type="button"
          color="inherit"
          variant="text"
          onClick={props?.onClose}
        >
          {formText?.btn?.discard}
        </Button>
        <Button type="submit" disabled={!isValid}>
          {formText?.btn?.success}
        </Button>
      </DialogActions>
    </form>
  )
}

export default FormStock
