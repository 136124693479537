import React, { useMemo } from 'react'
import {
  AppBar,
  Divider as MuiDivider,
  Drawer,
  Hidden,
  List,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
  Box,
} from '@mui/material'
import {
  Menu as MenuIcon,
  Inbox as InboxIcon,
  Assessment as AssessmentIcon,
  DirectionsCar as DirectionsCarIcon,
  AssignmentInd as AssignmentIndIcon,
  // Assignment as AssignmentIcon,
  ExitToApp as ExitToAppIcon,
} from '@mui/icons-material'
import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { rhythm } from 'utils/typography'

import './SideNav.css'

const NavLink = styled(RouterNavLink)`
  color: var(--color-text);
  text-decoration: none;
`

const Divider = styled(MuiDivider)`
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.12);
`

const drawerWidth = 300

interface SideNavLink {
  iconComponent: React.ReactNode
  text: string
  link: NavLinkProps
}

export function SideNav() {
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const menuButtonCss = useMemo(
    () => css`
      margin-right: ${rhythm(2)};
      @media only screen and (min-width: 1200px) {
        display: none;
      }
    `,
    []
  )

  // const drawerCss = useMemo(
  //   () => css`
  //     @media only screen and (min-width: 1200px) {
  //       width: ${drawerWidth};
  //       flex-shrink: 0;
  //     }
  //   `,
  //   []
  // )

  // const activeClass = (props: any) => (props?.isActive ? activeTest : undefined)

  const reports: SideNavLink[] = [
    {
      text: 'ภาพรวม',
      iconComponent: <AssessmentIcon />,
      link: {
        to: '/dashboard',
        end: true,
      },
    },
  ]

  const services: SideNavLink[] = [
    {
      text: 'บริการเช่ารถ',
      iconComponent: <DirectionsCarIcon />,
      link: {
        to: '/rental',
      },
    },
    {
      text: 'กล่องข้อความ',
      iconComponent: <InboxIcon />,
      link: {
        to: '/inboxes',

        end: true,
      },
    },
  ]

  const manages: SideNavLink[] = [
    {
      text: 'พนักงาน',
      iconComponent: <AssignmentIndIcon />,
      link: {
        to: '/staffs',
      },
    },
  ]

  const handleDrawerToggle = () => {
    setMobileOpen((prev) => !prev)
  }

  const drawer = (
    <div>
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" id="overview">
            ภาพรวม
          </ListSubheader>
        }
      >
        {reports.map((item) => (
          <NavLink key={item.text} {...item.link}>
            <ListItem button>
              <ListItemIcon>{item.iconComponent}</ListItemIcon>
              <ListItemText>{item.text}</ListItemText>
            </ListItem>
          </NavLink>
        ))}
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" id="car-rental">
            บริการ
          </ListSubheader>
        }
      >
        {services.map((item) => (
          <NavLink key={item.text} {...item.link}>
            <ListItem button>
              <ListItemIcon>{item.iconComponent}</ListItemIcon>
              <ListItemText>{item.text}</ListItemText>
            </ListItem>
          </NavLink>
        ))}
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" id="manage">
            จัดการ
          </ListSubheader>
        }
      >
        {manages.map((item) => (
          <NavLink key={item.text} {...item.link}>
            <ListItem button>
              <ListItemIcon>{item.iconComponent}</ListItemIcon>
              <ListItemText>{item.text}</ListItemText>
            </ListItem>
          </NavLink>
        ))}
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" id="manage">
            จัดการบัญชี
          </ListSubheader>
        }
      >
        <ListItem button>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>ออกจากระบบ</ListItemText>
        </ListItem>
      </List>
    </div>
  )

  return (
    <>
      <Hidden mdUp>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={menuButtonCss}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>

      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Hidden mdUp implementation="css">
          <Drawer
            anchor={'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: css`
                width: 300px;
              `,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Toolbar />
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            variant="permanent"
            open
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
          >
            <Toolbar />
            {drawer}
          </Drawer>
        </Hidden>
      </Box>
    </>
  )
}

export default SideNav
