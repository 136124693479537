import React from 'react'
import { Link } from 'react-router-dom'

export function NoMatch() {
  return (
    <>
      <h1>ไม่เจอหน้าที่ต้องการ</h1>
      <br />
      <Link to={'/'}>Go to home page</Link>
    </>
  )
}

export default NoMatch
