import React from 'react'
import { Layout } from 'components/Layout'

export function Dashboard() {
  return (
    <Layout>
      {' '}
      <h1>ภาพรวม</h1>
    </Layout>
  )
}

export default Dashboard
