import React from 'react'
import { useRoutes } from 'react-router-dom'

import { Dashboard } from './Dashboard'
import { Reservation } from './Reservation'
import { ReservationPlan } from './ReservationPlan'
import { Setting } from './Setting'
import { Stock } from './Stock'
import { VehicleDetail } from './VehicleDetail'

import { Layout } from './components/Layout'

export function Routes() {
  const element = useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          element: <Dashboard />,
          index: true,
        },
        {
          path: '/dashboard',
          element: <Dashboard />,
        },
        {
          path: '/reservation',
          element: <Reservation />,
        },
        {
          path: '/reservation-plan',
          element: <ReservationPlan />,
        },
        {
          path: '/setting',
          element: <Setting />,
        },
        {
          path: '/stock',
          element: <Stock />,
        },
        {
          path: '/vehicle-detail',
          element: <VehicleDetail />,
        },
      ],
    },
  ])

  return element
}

export default Routes
