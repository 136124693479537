import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import type { Inbox, InboxRowData } from './types'

interface InboxState {
  entities: { [id: number]: Inbox }
}

const initialState: InboxState = {
  entities: [],
}

export const loadedInboxes = createAsyncThunk('inboxes/loadedInboxes', () => {
  return [
    {
      id: '01',
      contact_status: 'unread',
      email: 'john.doe@email.local',
      first_name: 'John',
      last_name: 'Doe',
      phone_number: '0812345678',
      request_message: 'abc',
      title: 'สอบถามจองรถรายเดือน',
    },
    {
      id: '02',
      contact_status: 'read',
      email: 'john.doe@email.local',
      first_name: 'John',
      last_name: 'Doe',
      phone_number: '0812345678',
      request_message: 'abc',
      title: 'วิธีจ่ายเงิน',
    },
  ] as Inbox[]
})

export const inboxSlice = createSlice({
  name: 'inboxes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadedInboxes.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.entities = payload.reduce(
          (entities: { [id: number]: Inbox }, inbox: Inbox) => ({
            ...entities,
            [inbox.id]: inbox,
          }),
          {}
        )
      }
    )
  },
})

export default inboxSlice.reducer

const selectInboxes = createSelector(
  (state: RootState) => state,
  (state) => state.inboxes
)

export const selectInboxEntities = createSelector(
  selectInboxes,
  (state) => state.entities
)

export const selectAllInboxes = createSelector(
  selectInboxEntities,
  (entities) => Object.keys(entities).map((id: any) => entities[id])
)

export const selectInboxById = createSelector(
  [selectInboxEntities, (state, id) => id],
  (entities, id) => entities[id]
)

export const selectAllStockRows = createSelector(selectAllInboxes, (inboxes) =>
  inboxes.map(
    (inbox) =>
      ({
        id: inbox.id,
        contact_status: inbox.contact_status,
        email: inbox.email,
        full_name: `${inbox.first_name} ${inbox.last_name}`,
        phone_number: inbox.phone_number,
        title: inbox.title,
      } as InboxRowData)
  )
)
