import React from 'react'
import { css } from '@emotion/css'
import { Outlet } from 'react-router-dom'
import { TopNav } from '../TopNav'
import { Paper } from 'components/Paper'
import { rhythm } from 'utils/typography'

export function Layout() {
  return (
    <>
      <header>
        <h1>บริการรถเช่า</h1>
        <TopNav />
      </header>
      <main>
        <Paper
          className={css`
            padding: ${rhythm(1)};
          `}
        >
          <Outlet />
        </Paper>
      </main>
    </>
  )
}

export default Layout
