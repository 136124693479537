import { createSlice, createSelector } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import type { AlertType } from '../types'

type StockAlertState = Record<
  'error' | 'info' | 'success',
  { isOpen: boolean; message: string }
>

const initialState: StockAlertState = {
  error: { isOpen: false, message: '' },
  info: { isOpen: false, message: '' },
  success: { isOpen: false, message: '' },
}

export const stockAlertSlice = createSlice({
  name: 'stockAlert',
  initialState,
  reducers: {
    showAlert: (
      state,
      action: PayloadAction<{ type: AlertType; message: string }>
    ) => {
      state[action.payload.type] = {
        isOpen: true,
        message: action.payload.message,
      }
    },
    closedAlert: (state, action: PayloadAction<{ type: AlertType }>) => {
      state[action.payload.type] = {
        isOpen: false,
        message: '',
      }
    },
  },
})

export default stockAlertSlice.reducer

export const { closedAlert, showAlert } = stockAlertSlice.actions

export const selectStockAlert = createSelector(
  (state: RootState) => state.rental,
  (state) => state.stockAlert
)

export const selectStockInfoAlert = createSelector(
  selectStockAlert,
  (state) => state.info
)

export const selectStockSuccessAlert = createSelector(
  selectStockAlert,
  (state) => state.success
)

export const selectStockErrorAlert = createSelector(
  selectStockAlert,
  (state) => state.error
)
