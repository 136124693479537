import Typography from "typography";

export const typography = new Typography({
  baseFontSize: "17px",
  baseLineHeight: 1.7,
  headerFontFamily: ["Kanit", "sans-serif"],
  bodyFontFamily: ["Chakra Petch", "sans-serif"],
  scaleRatio: 2.3,
  headerColor: "hsl(0,0%, 10%)",
  headerWeight: 500,
  bodyColor: "hsl(0,0%, 40%)",
  boldWeight: 600,
  bodyWeight: 500,
});

export const { rhythm, scale } = typography;
