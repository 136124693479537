import React from 'react'
import Routes from './Routes'
import { Layout } from 'components/Layout'

export function Home() {
  return (
    <Layout>
      <Routes />
    </Layout>
  )
}

export default Home
