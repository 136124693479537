import React from 'react'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextField as MuiTextField, DialogActions } from '@mui/material'
import { Button as MuiButton } from 'components/Button'
import * as yup from 'yup'
import { rhythm } from 'utils/typography'

const ErrorMessage = styled.span`
  color: var(--color-error-main);
`

const TextField = styled(MuiTextField)`
  margin-top: ${rhythm(0.7)};
  margin-bottom: ${rhythm(0.7)};
`

const Button = styled(MuiButton)`
  margin-left: ${rhythm(0.5)};
`

const schema = yup.object({
  en_title: yup
    .string()
    .max(128, 'กรุณากรอกน้อยกว่า 128 ตัวอักษร')
    .required('กรุณากรอกข้อมูล'),
  th_title: yup
    .string()
    .max(128, 'กรุณากรอกน้อยกว่า 128 ตัวอักษร')
    .required('กรุณากรอกข้อมูล'),
  rate: yup.number().min(0, 'กรุณากรอกมากกว่า 0').required('กรุณากรอกข้อมูล'),
})

export interface FormLocationProps {
  defaultValues?: { th_title?: string; en_title?: string; rate?: number }
  onSubmit: (value: any) => void
  onClose: () => void
}

export function FormLocation(props: FormLocationProps) {
  const {
    formState: { isValid, errors },
    register,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: props?.defaultValues,
  })

  const formText = {
    en_title: {
      label: 'ชื่อสถานที่ภาษาอังกฤษ',
      placeholder: 'พิมพ์ชื่อสถานที่ภาษาอังกฤษ',
    },
    th_title: {
      label: 'ชื่อสถานที่ภาษาไทย',
      placeholder: 'พิมพ์ชื่อสถานที่ภาษาไทย',
    },
    rate: {
      label: 'ราคารับ-ส่งรถ',
      placeholder: 'พิมพ์ราคารับ-ส่งรถ',
    },
    btn: {
      success: 'บันทึก',
      discard: 'ยกเลิก',
    },
  }

  return (
    <form onSubmit={handleSubmit(props?.onSubmit)} autoComplete="off">
      <TextField
        {...register('th_title')}
        fullWidth
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        name="th_title"
        label={formText?.th_title?.label}
        placeholder={formText?.th_title?.placeholder}
        error={Boolean(errors?.th_title) && Boolean(errors?.th_title?.message)}
        helperText={
          <ErrorMessage>
            {errors?.th_title && errors?.th_title?.message}
          </ErrorMessage>
        }
      />
      <TextField
        {...register('en_title')}
        fullWidth
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        name="en_title"
        label={formText?.en_title?.label}
        placeholder={formText?.en_title?.placeholder}
        error={Boolean(errors?.en_title) && Boolean(errors?.en_title?.message)}
        helperText={
          <ErrorMessage>
            {errors?.en_title && errors?.en_title?.message}
          </ErrorMessage>
        }
      />
      <TextField
        {...register('rate')}
        fullWidth
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        name="rate"
        label={formText?.rate?.label}
        placeholder={formText?.rate?.placeholder}
        error={Boolean(errors?.rate) && Boolean(errors?.rate?.message)}
        helperText={
          <ErrorMessage>{errors?.rate && errors?.rate?.message}</ErrorMessage>
        }
      />
      <br />
      <DialogActions>
        <Button
          color="inherit"
          type="button"
          variant="text"
          onClick={props?.onClose}
        >
          {formText?.btn?.discard}
        </Button>
        <Button type="submit" disabled={!isValid}>
          {formText?.btn?.success}
        </Button>
      </DialogActions>
    </form>
  )
}

export default FormLocation
