import React from 'react'
import { useAppDispatch } from 'store'
import { addedLocation } from 'features/rental/location/locationSlice'
import { Button, ButtonProps } from 'components/Button'
import { Dialog } from 'components/Dialog'
import { FormLocation } from '../FormLocation'

export interface ButtonAddLocationProps {
  buttonProps?: ButtonProps
}

// ? container
export function ButtonAddLocation(props: ButtonAddLocationProps) {
  const dispatch = useAppDispatch()
  const [isOpenDialog, setIsOpenDialog] = React.useState<boolean>(false)
  const headerText = `เพิ่มสถานที่`

  const handleCloseClick = () => setIsOpenDialog(false)

  function handleSubmit(payload: any) {
    dispatch(addedLocation(payload))
    handleCloseClick()
  }

  return (
    <>
      <Button {...props?.buttonProps} onClick={() => setIsOpenDialog(true)}>
        {headerText}
      </Button>
      <Dialog
        isOpen={isOpenDialog}
        onClose={handleCloseClick}
        headerText={headerText}
        component={
          <FormLocation onSubmit={handleSubmit} onClose={handleCloseClick} />
        }
      />
    </>
  )
}

export default ButtonAddLocation
