export type VehicleTypeTH = 'รถเก๋ง' | 'รถSUV' | 'รถกระบะ' | 'รถตู้'

export type VehicleTypeEN = 'sedan' | 'suv' | 'pickup-truck' | 'van'

export function ConvertVehicleType(vehicleType: VehicleTypeEN) {
  const table: Record<VehicleTypeEN, VehicleTypeTH> = {
    'pickup-truck': 'รถกระบะ',
    sedan: 'รถเก๋ง',
    suv: 'รถSUV',
    van: 'รถตู้',
  }

  return Object.keys(table).includes(vehicleType) ? table[vehicleType] : ''
}

export default ConvertVehicleType
