import React from 'react'
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material'

export type { ButtonProps } from '@mui/material'

export function Button(props: MuiButtonProps) {
  return (
    <MuiButton
      variant="contained"
      disableElevation
      color="primary"
      {...props}
    />
  )
}

export default Button
