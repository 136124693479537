import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { rhythm } from 'utils/typography'
import { DialogActions } from '@mui/material'
import { Button } from 'components/Button'

const Wrapper = styled.div`
  margin-top: ${rhythm(0.5)};
`

export interface DialogContentDeleteProps {
  content: React.ReactNode
  onSuccess: () => void
  onDiscard: () => void
}

export function DialogContentDelete(props: DialogContentDeleteProps) {
  const btnText = {
    success: 'ตกลง',
    discard: 'ยกเลิก',
  }

  return (
    <>
      <Wrapper>{props?.content}</Wrapper>
      <DialogActions>
        <Button color="inherit" variant="text" onClick={props?.onDiscard}>
          {btnText?.discard}
        </Button>
        <Button
          className={css`
            margin-left: ${rhythm(0.25)};
          `}
          onClick={props?.onSuccess}
        >
          {btnText?.success}
        </Button>
      </DialogActions>
    </>
  )
}

export default DialogContentDelete
