import React, { useState } from 'react'
import { css } from '@emotion/css'
import {
  TableContainer,
  Table,
  TablePagination,
  TableBody,
} from '@mui/material'
import { TableHead, HeadCell, Order } from 'components/TableHead'
import { Rows, Data } from './Rows'
import { getComparator, stableSort } from 'utils/table-stable-sort'
import { Dialog, DialogContentDelete } from 'components/Dialog'
import { FormVehicleDetail } from '../FormVehicleDetail'

import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
  completed,
  selectedDeleteById,
  selectedUpdateById,
  selectSelectedVehicleDetail,
} from 'features/rental/vehicle-detail/selectedVehicleDetailSlice'
import {
  selectAllVehicleDetailRows,
  removedVehicleDetailById,
  updatedVehicleDetailById,
} from 'features/rental/vehicle-detail/vehicleDetailSlice'

type Property = keyof Data

const headCells: HeadCell<Property>[] = [
  {
    id: 'th_title',
    label: 'ชื่อภาษาไทย',
    disablePadding: true,
    numeric: false,
  },
  {
    id: 'en_title',
    label: 'ชื่อภาษาอังกฤษ',
    disablePadding: true,
    numeric: false,
  },
  {
    id: 'vehicle_type',
    label: 'ชนิดรถ',
    disablePadding: false,
    numeric: false,
  },
]

// ? container
export function TableVehicleDetail() {
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<Property>('en_title')

  const rows = useSelector(selectAllVehicleDetailRows)

  const selectedVehicleDetail = useSelector(selectSelectedVehicleDetail)
  const dispatch = useAppDispatch()

  function hanleEditClick(payload: any) {
    dispatch(selectedUpdateById(payload.id))
  }
  function handleDialogEditClose() {
    dispatch(completed())
  }
  function handleDeleteClick(payload: any) {
    dispatch(selectedDeleteById(payload.id))
  }

  function handleDialogDeleteClose() {
    dispatch(completed())
  }

  function handleSubmit(payload: any) {
    dispatch(updatedVehicleDetailById(payload))
  }

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  // const emptyRows = React.useMemo(
  //   () => (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0),
  //   [page, rowsPerPage, rows]
  // )

  function hanldeRequestSort(property: Property) {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  function handlePageChange(event: unknown, newPage: number) {
    setPage(newPage)
  }

  function handleRowsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  return (
    <>
      <Dialog
        headerText="แก้ไข"
        component={
          <FormVehicleDetail
            onClose={handleDialogEditClose}
            onSubmit={(payload) =>
              handleSubmit({ ...payload, id: selectedVehicleDetail.entity?.id })
            }
            vehicleDetail={{
              en_title: selectedVehicleDetail.entity?.en_title,
              gallery: [],
              th_title: selectedVehicleDetail.entity?.th_title,
              vehicle_type: selectedVehicleDetail.entity?.vehicle_type,
            }}
          />
        }
        isOpen={selectedVehicleDetail.action === 'update'}
        onClose={handleDialogEditClose}
      />
      <Dialog
        headerText={`ต้องการลบข้อมูล ${selectedVehicleDetail.entity?.th_title}`}
        component={
          <DialogContentDelete
            content={null}
            onDiscard={handleDialogDeleteClose}
            onSuccess={() =>
              dispatch(
                removedVehicleDetailById(selectedVehicleDetail.entity?.id)
              )
            }
          />
        }
        isOpen={selectedVehicleDetail.action === 'delete'}
        onClose={handleDialogDeleteClose}
      />

      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="table-vehicle-detail">
          <TableHead<Property>
            headCells={headCells}
            onRequestSort={hanldeRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            <Rows
              data={stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )}
              headCells={headCells}
              onDelete={handleDeleteClick}
              onEdit={hanleEditClick}
            />
          </TableBody>
        </Table>
      </TableContainer>
      {!rows?.length && (
        <p
          className={css`
            text-align: center;
            text-decoration: underline;
          `}
        >
          ไม่มีข้อมูล
        </p>
      )}
      <TablePagination
        labelRowsPerPage="แถวต่อหน้า"
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={handleRowsPerPageChange}
        onPageChange={handlePageChange}
      />
    </>
  )
}

export default TableVehicleDetail
