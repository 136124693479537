import React from 'react'
import { Snackbar, Alert } from '@mui/material'

export interface SnackbarAlertProps {
  open: boolean
  action: 'info' | 'success' | 'error'
  onClose: () => void
  message: string
}

export function SnackbarAlert(props: SnackbarAlertProps) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={5000}
      open={props?.open}
      onClose={props?.onClose}
    >
      <Alert onClose={props?.onClose} severity={props?.action}>
        {props?.message}
      </Alert>
    </Snackbar>
  )
}

export default SnackbarAlert
