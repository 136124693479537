import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import styled from '@emotion/styled'
import { Paper as MuiPaper } from 'components/Paper'
import { Toolbar as MuiToolbar } from '@mui/material'
import { rhythm } from 'utils/typography'

const NavLink = styled(RouterLink)`
  text-decoration: none;
  color: var(--color-text);
  margin-right: ${rhythm(1.5)};
  white-space: nowrap;
`

const Toolbar = styled(MuiToolbar)`
  align-content: center;
  overflow-x: auto;
  .active {
    text-decoration: underline;
  }
`

const Paper = styled(MuiPaper)`
  margin: 0px 0px ${rhythm(2)} 0px !important;
`

export interface TopNavProps {}

export function TopNav(props: TopNavProps) {
  const links: { title: string; to: string }[] = [
    { title: 'ภาพรวม', to: './dashboard' },
    { title: 'การจอง', to: './reservation' },
    { title: 'แผนการจอง', to: './reservation-plan' },
    { title: 'ข้อมูลรถ', to: './vehicle-detail' },
    { title: 'คลังรถ', to: './stock' },
    { title: 'ตั้งค่าการจอง', to: './setting' },
  ]

  return (
    <Paper>
      <Toolbar>
        {links?.map((link) => (
          <NavLink key={link?.to} to={link.to}>
            {link.title}
          </NavLink>
        ))}
      </Toolbar>
    </Paper>
  )
}

export default TopNav
