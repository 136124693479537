import React from 'react'
import { ButtonAddPlan } from './components/ButtonAddPlan'

// todo dialog add
// todo dialog edit
// todo dialog delete
// todo
export function ReservationPlan() {
  return (
    <>
      <h2>แผนการจอง</h2>
      <ButtonAddPlan />
      <p>ปุ่มการจอง container</p>
      <p>ปุ่มสลับ calendar กับ table</p>
      <p>schedule plan เป็น container</p>
      <p>ตาราง schedule </p>
    </>
  )
}

export default ReservationPlan
