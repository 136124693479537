import React from 'react'
import { css } from '@emotion/css'
import { TableReservation } from './components/TableReservation'
import { Button } from 'components/Button'

export function Reservation() {
  return (
    <>
      <h2>การจอง</h2>

      <div
        className={css`
          display: flex;
        `}
      >
        <span
          className={css`
            margin-left: auto !important;
          `}
        >
          <Button
            className={css`
              margin-right: 15px !important;
            `}
          >
            จองรถ
          </Button>
          <Button>ตัวกรอง</Button>
        </span>
      </div>
      <TableReservation />
    </>
  )
}

export default Reservation
