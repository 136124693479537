import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'

import { selectLocationById } from './locationSlice'
import { Location } from '../types'

interface SelectedLocationState {
  action: 'update' | 'delete' | null
  entity: Location | null
}

const initialState: SelectedLocationState = {
  action: null,
  entity: null,
}

export const selectedUpdateById = createAsyncThunk(
  'locations/selectedUpdateById',
  async (id: string, { getState }) => {
    return selectLocationById(getState() as RootState, id)
  }
)

export const selectedDeleteById = createAsyncThunk(
  'locations/selectedDeleteById',
  async (id: string, { getState }) => {
    return selectLocationById(getState() as RootState, id)
  }
)

export const selectedLocationSlice = createSlice({
  name: 'selectedLocation',
  initialState,
  reducers: {
    completed: (state) => {
      state.action = null
      state.entity = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(selectedUpdateById.fulfilled, (state, action) => {
        state.action = 'update'
        state.entity = action.payload
      })
      .addCase(
        selectedDeleteById.fulfilled,
        (state, action: PayloadAction<Location>) => {
          state.action = 'delete'
          state.entity = action.payload
        }
      )
  },
})

export const { completed } = selectedLocationSlice.actions

export const selectRental = (state: RootState) => state.rental

export const selectSelectedLocation = createSelector(
  selectRental,
  (state) => state.selectedLocation
)
export default selectedLocationSlice.reducer
