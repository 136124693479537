import { combineReducers } from '@reduxjs/toolkit'
import selectedLocationReducer from './location/selectedLocationSlice'
import locationsReducer from './location/locationSlice'
import locationAlertReducer from './location/locationAlertSlice'
import vehicleDetailsReducer from './vehicle-detail/vehicleDetailSlice'
import selectedVehicleDetailReducer from './vehicle-detail/selectedVehicleDetailSlice'
import vehicleDetailAlertReducer from './vehicle-detail/vehicleDetailAlertSlice'
import stocksReducer from './stock/stockSlice'
import stockAlertReducer from './stock/stockAlertSlice'
import selectedStockReducer from './stock/selectedStockSlice'

export const rentalReducer = combineReducers({
  selectedLocation: selectedLocationReducer,
  locations: locationsReducer,
  locationAlert: locationAlertReducer,
  vehicleDetails: vehicleDetailsReducer,
  selectedVehicleDetail: selectedVehicleDetailReducer,
  vehicleDetailAlert: vehicleDetailAlertReducer,
  stocks: stocksReducer,
  stockAlert: stockAlertReducer,
  selectedStock: selectedStockReducer,
})
