import React from 'react'
import styled from '@emotion/styled'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  TextField as MuiTextField,
  MenuItem,
  DialogActions,
} from '@mui/material'
import { Button as MuiButton } from 'components/Button'
import * as yup from 'yup'
import { rhythm } from 'utils/typography'

export type VehicleTypeTH = 'รถเก๋ง' | 'รถSUV' | 'รถกระบะ' | 'รถตู้'
export const vehicle_typeTH: VehicleTypeTH[] = [
  'รถเก๋ง',
  'รถSUV',
  'รถกระบะ',
  'รถตู้',
]
export type VehicleTypeEN = 'sedan' | 'suv' | 'pickup truck' | 'van'
export const vehicle_typeEN: VehicleTypeEN[] = [
  'sedan',
  'suv',
  'pickup truck',
  'van',
]

const vehicle_type = [
  { id: 'sedan', name: 'รถเก๋ง' },
  { id: 'suv', name: 'รถSUV' },
  { id: 'pickup truck', name: 'รถกระบะ' },
  { id: 'van', name: 'รถตู้' },
]

const ErrorMessage = styled.span`
  color: var(--color-error-main);
`

const TextField = styled(MuiTextField)`
  margin-top: ${rhythm(0.7)};
  margin-bottom: ${rhythm(0.7)};
`

const Button = styled(MuiButton)`
  margin-left: ${rhythm(0.5)};
`

export interface VehicleDetail {
  th_title?: string
  en_title?: string
  vehicle_type?: string
  gallery?: string[]
}

const schema = yup.object({
  en_title: yup
    .string()
    .max(128, 'กรุณากรอกน้อยกว่า 128 ตัวอักษร')
    .required('กรุณากรอกข้อมูล'),
  th_title: yup
    .string()
    .max(128, 'กรุณากรอกน้อยกว่า 128 ตัวอักษร')
    .required('กรุณากรอกข้อมูล'),
  vehicle_type: yup
    .string()
    .max(128, 'กรุณากรอกน้อยกว่า 128 ตัวอักษร')
    .required('กรุณากรอกข้อมูล'),
})

export interface FormVehicleDetailProps {
  vehicleDetail?: VehicleDetail
  onSubmit: (value: any) => void
  onClose: () => void
}

// todo เพิ่ม upload รูป cover กับ รูป gallery
export function FormVehicleDetail(props: FormVehicleDetailProps) {
  const { handleSubmit, register, formState, control } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      th_title: props?.vehicleDetail?.th_title,
      en_title: props?.vehicleDetail?.en_title,
      vehicle_type: props?.vehicleDetail?.vehicle_type || 'sedan',
    },
  })
  const { isValid, errors } = formState

  const isDisabled = !isValid

  const btnActionText = {
    btnSuccess: 'บันทึก',
    btnDiscard: 'ยกเลิก',
  }

  const textFieldText = {
    th_title: {
      label: 'ชื่อรุ่นรถภาษาไทย',
      placeholder: 'พิมพ์ชื่อรุ่นรถภาษาไทย',
    },
    en_title: {
      label: 'ชื่อรุ่นรถภาษาอังกฤษ',
      placeholder: 'พิมพ์ชื่อรุ่นรถภาษาอังกฤษ',
    },
    vehicle_type: {
      label: 'ประเภทรถ',
      placeholder: 'เลือกประเภทรถ',
    },
  }

  return (
    <form onSubmit={handleSubmit(props?.onSubmit)} autoComplete="off">
      <TextField
        {...register('en_title')}
        fullWidth
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        name="en_title"
        label={textFieldText?.en_title?.label}
        placeholder={textFieldText?.en_title?.placeholder}
        error={Boolean(errors?.en_title) && Boolean(errors?.en_title?.message)}
        helperText={
          <ErrorMessage>
            {errors?.en_title && errors?.en_title?.message}
          </ErrorMessage>
        }
      />
      <TextField
        fullWidth
        {...register('th_title')}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        name="th_title"
        label={textFieldText?.th_title?.label}
        placeholder={textFieldText?.th_title?.placeholder}
        error={Boolean(errors?.th_title) && Boolean(errors?.th_title?.message)}
        helperText={
          <ErrorMessage>
            {errors?.th_title && errors?.th_title?.message}
          </ErrorMessage>
        }
      />
      <Controller
        name="vehicle_type"
        control={control}
        render={({ field, formState: { errors } }) => (
          <TextField
            select
            fullWidth
            {...field}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            label={textFieldText?.vehicle_type?.label}
            placeholder={textFieldText?.vehicle_type?.placeholder}
            error={
              Boolean(errors?.vehicle_type) &&
              Boolean(errors?.vehicle_type?.message)
            }
            helperText={
              <ErrorMessage>
                {errors?.vehicle_type && errors?.vehicle_type?.message}
              </ErrorMessage>
            }
          >
            {vehicle_type?.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <br />
      <DialogActions>
        <Button type="button" variant="text" onClick={props?.onClose}>
          {btnActionText?.btnDiscard}
        </Button>
        <Button type="submit" disabled={isDisabled}>
          {btnActionText?.btnSuccess}
        </Button>
      </DialogActions>
    </form>
  )
}

export default FormVehicleDetail
