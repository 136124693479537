import React from 'react'
import { Layout } from 'components/Layout'
import { css } from '@emotion/css'
import { Paper } from 'components/Paper'
import { rhythm } from 'utils/typography'

import { useAppDispatch } from 'store'
import { loadedStaffs } from 'features/staff/staffSlice'
import { TableStaff } from 'features/staff/components/TableStaff'

export function Staffs() {
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    dispatch(loadedStaffs())
  }, [dispatch])
  return (
    <Layout>
      <header>
        <h1>พนักงาน</h1>
      </header>
      <main>
        <Paper
          className={css`
            padding: ${rhythm(1)};
          `}
        >
          <TableStaff />
        </Paper>
      </main>
    </Layout>
  )
}

export default Staffs
