import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import { Staff, StaffRowData } from './types'

interface StaffState {
  entities: { [id: number]: Staff }
}

const initialState: StaffState = {
  entities: [],
}

export const loadedStaffs = createAsyncThunk('staffs/loadedStaffs', () => {
  return [
    {
      id: '01',
      display_name: 'john doe',
      email: 'john.doe@mail.local',
      is_changed_password: true,
      is_deleted: false,
      is_suspended: false,
      role: 'admin',
    },
    {
      id: '02',
      display_name: '02john doe',
      email: '02john.doe@mail.local',
      is_changed_password: true,
      is_deleted: false,
      is_suspended: true,
      role: 'editor',
    },
    {
      id: '03',
      display_name: '03john doe',
      email: '03john.doe@mail.local',
      is_changed_password: true,
      is_deleted: false,
      is_suspended: false,
      role: 'staff',
    },
  ] as Staff[]
})

export const staffSlice = createSlice({
  name: 'staffs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadedStaffs.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.entities = action.payload.reduce(
          (entities: { [id: number]: Staff }, staff: Staff) => ({
            ...entities,
            [staff.id]: staff,
          }),
          {}
        )
      }
    )
  },
})

export default staffSlice.reducer

const selectStaffs = createSelector(
  (state: RootState) => state,
  (state) => state.staffs
)

export const selectStaffEntities = createSelector(
  selectStaffs,
  (state) => state.entities
)

export const selectAllStaffs = createSelector(selectStaffEntities, (entities) =>
  Object.keys(entities).map((id: any) => entities[id])
)

export const selectStaffById = createSelector(
  [selectStaffEntities, (state, id) => id],
  (entities, id) => entities[id]
)

export const selectAllStaffRows = createSelector(selectAllStaffs, (staffs) =>
  staffs.map(
    (staff) =>
      ({
        id: staff.id,
        display_name: staff.display_name,
        email: staff.email,
        is_suspended: staff.is_suspended ? 'ระงับ' : 'ยังใช้งาน',
        role: staff.role,
      } as StaffRowData)
  )
)
