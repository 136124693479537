import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import { selectVehicleDetailById } from './vehicleDetailSlice'
import type { VehicleDetail } from '../types'

interface SelecteVehicleDetailState {
  action: 'update' | 'delete' | null
  entity: VehicleDetail | null
}

const initialState: SelecteVehicleDetailState = {
  action: null,
  entity: null,
}

export const selectedUpdateById = createAsyncThunk(
  'vehicleDetails/selectedUpdateById',
  async (id: string, { getState }) => {
    return selectVehicleDetailById(getState() as RootState, id)
  }
)

export const selectedDeleteById = createAsyncThunk(
  'vehicleDetails/selectedDeleteById',
  async (id: string, { getState }) => {
    return selectVehicleDetailById(getState() as RootState, id)
  }
)

export const selectedVehicleDetailSlice = createSlice({
  name: 'selectedVehicleDetail',
  initialState,
  reducers: {
    completed: (state) => {
      state.action = null
      state.entity = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        selectedUpdateById.fulfilled,
        (state, action: PayloadAction<VehicleDetail>) => {
          state.action = 'update'
          state.entity = action.payload
        }
      )
      .addCase(
        selectedDeleteById.fulfilled,
        (state, action: PayloadAction<VehicleDetail>) => {
          state.action = 'delete'
          state.entity = action.payload
        }
      )
  },
})

export const { completed } = selectedVehicleDetailSlice.actions

export const selectSelectedVehicleDetail = createSelector(
  (state: RootState) => state.rental,
  (state) => state.selectedVehicleDetail
)

export default selectedVehicleDetailSlice.reducer
