import React from 'react'
import { Layout } from 'components/Layout'
import { css } from '@emotion/css'
import { Paper } from 'components/Paper'
import { rhythm } from 'utils/typography'

export function InBoxes() {
  return (
    <Layout>
      <header>
        <h1>กล่องข้อความ</h1>
      </header>
      <main>
        <Paper
          className={css`
            padding: ${rhythm(1)};
          `}
        >
          table
        </Paper>
      </main>
    </Layout>
  )
}

export default InBoxes
