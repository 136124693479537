import React from 'react'
import { Button } from 'components/Button'
import { Dialog } from 'components/Dialog'
import { FormPlan } from '../FormPlan'

// ? container
export function ButtonAddPlan() {
  const [isOpenDialog, setIsOpenDialog] = React.useState<boolean>(false)
  const headerText = `เพิ่มแผนกำหนดการ`

  const handleCloseClick = () => setIsOpenDialog(false)

  function handleSubmit(value: any) {
    console.log(value)
    handleCloseClick()
  }

  return (
    <>
      <Button onClick={() => setIsOpenDialog(true)}>{headerText}</Button>
      <Dialog
        isOpen={isOpenDialog}
        onClose={handleCloseClick}
        headerText={headerText}
        component={
          <FormPlan
            onSubmit={handleSubmit}
            onClose={handleCloseClick}
            licensePlates={[
              { id: 'license-01', name: 'abc01' },
              { id: 'license-02', name: 'abc02' },
            ]}
          />
        }
      />
    </>
  )
}

export default ButtonAddPlan
