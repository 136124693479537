import React from 'react'
import { useAppDispatch } from 'store'
import { addedStock } from 'features/rental/stock/stockSlice'
import { Button, ButtonProps } from 'components/Button'
import { Dialog } from 'components/Dialog'
import { FormStock } from '../FormStock'

export interface ButtonAddStockProps {
  buttonProps?: ButtonProps
}

// ? container
export function ButtonAddStock(props: ButtonAddStockProps) {
  const dispatch = useAppDispatch()

  const [isOpenDialog, setIsOpenDialog] = React.useState<boolean>(false)
  const headerText = `เพิ่มรุ่นรถ`

  const handleCloseClick = () => setIsOpenDialog(false)

  function handleSubmit(payload: any) {
    dispatch(addedStock(payload))
    handleCloseClick()
  }

  return (
    <>
      <Button {...props?.buttonProps} onClick={() => setIsOpenDialog(true)}>
        {headerText}
      </Button>
      <Dialog
        isOpen={isOpenDialog}
        onClose={handleCloseClick}
        headerText={headerText}
        component={
          <FormStock
            onClose={handleCloseClick}
            onSubmit={handleSubmit}
            defaultValues={{ vehicle: 'vehicle-01', status: 'working' }}
            vehicles={[
              { id: 'vehicle-01', name: 'toyota' },
              { id: 'vehicle-02', name: 'mazda' },
            ]}
          />
        }
      />
    </>
  )
}

export default ButtonAddStock
