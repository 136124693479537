import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import { Price } from 'utils/price'
import { completed } from './selectedLocationSlice'
import { showAlert } from './locationAlertSlice'
import { Location, LocationData } from '../types'

const mocks: Location[] = [
  {
    id: '01',
    th_title: 'สถานบิน',
    en_title: 'Airport',
    rate: 0,
  },
  {
    id: '02',
    th_title: 'ตัวเมือง02',
    en_title: 'Town',
    rate: 1300,
  },
  {
    id: '03',
    th_title: 'ตัวเมือง',
    en_title: 'Town',
    rate: 1300,
  },
  {
    id: '04',
    th_title: 'ตัวเมือง',
    en_title: 'Town',
    rate: 1300,
  },
  {
    id: '05',
    th_title: 'ตัวเมือง',
    en_title: 'Town',
    rate: 1300,
  },
  {
    id: '06',
    th_title: 'ตัวเมือง',
    en_title: 'Town',
    rate: 1300,
  },
  {
    id: '07',
    th_title: 'ตัวเมือง01',
    en_title: 'Town',
    rate: 1300,
  },
  {
    id: '08',
    th_title: 'ตัวเมือง',
    en_title: 'Town',
    rate: 1300,
  },
  {
    id: '09',
    th_title: 'ตัวเมือง',
    en_title: 'Town',
    rate: 1300,
  },
  {
    id: '10',
    th_title: 'ตัวเมือง',
    en_title: 'Town',
    rate: 1300,
  },
]

interface LocationState {
  entities: { [id: number]: Location }
}

const initialState: LocationState = {
  entities: mocks.reduce(
    (entities: { [id: number]: Location }, location: Location) => ({
      ...entities,
      [location.id]: location,
    }),
    {}
  ),
}

export const removedLocationById = createAsyncThunk(
  'locations/removedLocationById',
  (id: any, { dispatch }) => {
    dispatch(completed())
    dispatch(showAlert({ type: 'success', message: 'ลบสำเร็จ' }))
    return id
  }
)

export const updatedLocationById = createAsyncThunk(
  'locations/updatedLocationById',
  (payload: any, { dispatch }) => {
    dispatch(completed())
    dispatch(showAlert({ type: 'success', message: 'อัปเดทสำเร็จ' }))
    return payload
  }
)

export const addedLocation = createAsyncThunk(
  'locations/addedLocation',
  (payload: any, { dispatch }) => {
    dispatch(showAlert({ type: 'success', message: 'เพิ่มสำเร็จ' }))
    return payload
  }
)

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addedLocation.fulfilled, (state, action: PayloadAction<any>) => {
        const id = Object.keys(state.entities).length + 100
        state.entities = {
          ...state.entities,
          [id]: { ...action.payload, id },
        }
      })
      .addCase(
        updatedLocationById.fulfilled,
        (state, action: PayloadAction<any>) => {
          const payload = action.payload
          state.entities = { ...state.entities, [payload.id]: payload }
        }
      )
      .addCase(
        removedLocationById.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { [action.payload]: removed, ...entities } = state.entities
          state.entities = entities
        }
      )
  },
})

// export const {  } = locationsSlice.actions

const selectLocations = createSelector(
  (state: RootState) => state.rental,
  (state) => state.locations
)

export const selectLocationEntities = createSelector(
  selectLocations,
  (state) => state.entities
)

export const selectAllLocations = createSelector(
  selectLocationEntities,
  (entities) => Object.keys(entities).map((id: any) => entities[id])
)

export const selectLocationById = createSelector(
  [selectLocationEntities, (state, id) => id],
  (entities, id) => entities[id]
)

export const selectAllLocationRows = createSelector(
  selectAllLocations,
  (locations) =>
    locations.map(
      (location) =>
        ({ ...location, rate: Price(location.rate) } as LocationData)
    )
)

export default locationsSlice.reducer
