import React from 'react'
import { css } from '@emotion/css'
import { useAppDispatch } from 'store'
import { useSelector } from 'react-redux'
import {
  selectStockErrorAlert,
  selectStockInfoAlert,
  selectStockSuccessAlert,
  closedAlert,
} from 'features/rental/stock/stockAlertSlice'
import { loadedStocks } from 'features/rental/stock/stockSlice'
import { ButtonAddStock } from 'features/rental/stock/ButtonAddStock'
import { TableStock } from 'features/rental/stock/TableStock'
import { SnackbarAlert } from 'components/SnackbarAlert'

export function Stock() {
  const errorAlert = useSelector(selectStockErrorAlert)
  const infoAlert = useSelector(selectStockInfoAlert)
  const successAlert = useSelector(selectStockSuccessAlert)

  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(loadedStocks())
  }, [dispatch])
  return (
    <>
      <SnackbarAlert
        action="error"
        open={errorAlert.isOpen}
        message={errorAlert.message}
        onClose={() => dispatch(closedAlert({ type: 'error' }))}
      />
      <SnackbarAlert
        action="info"
        open={infoAlert.isOpen}
        message={infoAlert.message}
        onClose={() => dispatch(closedAlert({ type: 'info' }))}
      />
      <SnackbarAlert
        action="success"
        open={successAlert.isOpen}
        message={successAlert.message}
        onClose={() => dispatch(closedAlert({ type: 'success' }))}
      />
      <h2>คลังรถ</h2>
      <div
        className={css`
          display: flex;
        `}
      >
        <ButtonAddStock
          buttonProps={{
            className: css`
              margin-left: auto !important;
            `,
          }}
        />
      </div>

      <TableStock />
    </>
  )
}

export default Stock
